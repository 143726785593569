import React, { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

/**
 * @param {FunctionComponent<Props>}
 */
const MetaTags = ({
  pageTitle,
  pageDescription,
  lang,
  pageAuthor,
  keywords,
  pathname,
  pageThumbnailImage,
  meta,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query GetSiteMetaQuery {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const metaTitle = pageTitle || site.siteMetadata.title
  const metaDescription = pageDescription || site.siteMetadata.description
  const metaAuthor = pageAuthor || site.siteMetadata.author
  const metaKeywords =
    keywords && keywords.length > 0
      ? keywords.join(',')
      : site.siteMetadata.keywords.join(',')
  const pageThumbnailImageAbsolutePath =
    pageThumbnailImage && pageThumbnailImage.src
      ? `${site.siteMetadata.siteUrl}${pageThumbnailImage.src}`
      : ''

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={metaTitle === site.siteMetadata.title ? '%s' : `%s | ${site.siteMetadata.title}`}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: 'theme-color',
          content: '#fff',
        },
        {
          itemProp: 'name',
          content: metaTitle,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          itemProp: 'description',
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:creator',
          content: metaAuthor,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          pageThumbnailImageAbsolutePath
            ? [
                {
                  property: 'og:image',
                  content: pageThumbnailImageAbsolutePath,
                },
                {
                  name: 'twitter:image',
                  content: pageThumbnailImageAbsolutePath,
                },
                {
                  itemProp: 'image',
                  content: pageThumbnailImageAbsolutePath,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ].concat(
                pageThumbnailImage.width && pageThumbnailImage.height
                  ? [
                      {
                        property: 'og:image:width',
                        content: pageThumbnailImage.width,
                      },
                      {
                        property: 'og:image:height',
                        content: pageThumbnailImage.height,
                      },
                    ]
                  : []
              )
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ]
        )
        .concat(meta)}
    />
  )
}

MetaTags.defaultProps = {
  lang: 'en',
  meta: [],
}

MetaTags.propTypes = {
  pathname: PropTypes.string,
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  pageThumbnailImage: PropTypes.shape({
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  pageAuthor: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.arrayOf(PropTypes.object),
  lang: PropTypes.string,
}

export default MetaTags
