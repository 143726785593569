import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: 'none',
    color: 'unset',
  }
}))

const CustomLink = ({ to, children, ...rest }) => {
  const classes = useStyles()
  return (
    <Link
      to={to}
      className={classes.root}
      {...rest}
    >
      {children}
    </Link>
  )
}

CustomLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  rest: PropTypes.object,
}

export default CustomLink
