import React from 'react'
import HomeOutlined from '@material-ui/icons/HomeOutlined'
import BookIcon from '@material-ui/icons/Book'
import InfoIcon from '@material-ui/icons/Info'

const navConfig = [
  {
    title: 'Home',
    href: '/',
    icon: <HomeOutlined />,
    access_level_num: 0,
  },
  {
    title: 'Creations',
    href: '/creations',
    icon: <BookIcon />,
    access_level_num: 0,
  },
  // {
  //   title: 'Blog',
  //   href: '/blog',
  //   icon: <BookIcon />,
  //   access_level_num: 0,
  // },
  {
    title: 'About',
    href: '/about',
    icon: <InfoIcon />,
    access_level_num: 0,
  },
]

export default navConfig
