import React from 'react'
import { Link } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import GitHubIcon from '@material-ui/icons/GitHub'
import navConfig from './navConfig'

const useStyles = makeStyles((theme) => ({
  root: {},
  footer: {
    height: '17rem',
    padding: '1rem 0',
    borderTop: `10px solid ${theme.palette.secondary.main}`,
    background: theme.palette.primary.main,
  },
  footerContent: {
    // background: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerNavItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerNavItem: {
    padding: '0.1rem 0.4rem',
    margin: '0.5rem 0.5rem',
    background: theme.palette.primary.dark,
    color: '#aeaeae',
  },
  footerTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  footerTitle: {
    padding: '10px 20px',
    color: '#f5f5f5',
    backgroundColor: theme.palette.primary.light,
    transform: 'skew(-15deg)',
  },
  linksContainer: {
    color: '#aeaeae',
    padding: '1rem 0rem',
    width: '11rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  creditsText: {
    textAlign: 'center',
    color: '#aeaeae',
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      component="footer"
      alignItems="center"
      justify="center"
      className={classes.footer}
    >
      <Grid
        item
        xs={11}
        sm={11}
        md={9}
        lg={8}
        xl={7}
        xxl={6}
      >
        <div className={classes.footerContent}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={5}
              xl={5}
              xxl={5}
            >
              <div className={classes.footerNavItemContainer}>
                {navConfig.map((obj) => (
                  <div key={obj.href}>
                    <Link to={obj.href} style={{ color: 'unset', textDecoration: 'none' }}>
                      <Typography className={classes.footerNavItem}>
                        {obj.title}
                      </Typography>
                    </Link>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid
              item
              xs={10}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              style={{ margin: '1.2rem 0rem' }}
            >
              <div className={classes.footerTitleContainer}>
                <Typography
                  variant="h6"
                  noWrap
                  className={classes.footerTitle}
                >
                  Creations By Nilay
                </Typography>
                <div className={classes.linksContainer}>
                  <a
                    href="https://www.instagram.com/in_solitud"
                    style={{ color: 'unset', textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href="https://github.com/nilaysavant"
                    style={{ color: 'unset', textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GitHubIcon />
                  </a>
                  <a
                    href="https://in.linkedin.com/in/nilay-savant"
                    style={{ color: 'unset', textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                  <a
                    href="https://twitter.com/nilay_savant"
                    style={{ color: 'unset', textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterIcon />
                  </a>
                  {/* <a
                  href="https://www.facebook.com/nilaylive"
                  style={{ color: 'unset', textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a> */}
                </div>
              </div>
            </Grid>
          </Grid>
          <Typography
            variant="subtitle2"
            className={classes.creditsText}
          >
            ©2020 Created by Nilay Savant
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}

export default Footer
