import React from 'react'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'
import { Helmet } from 'react-helmet'
import { makeStyles, fade } from '@material-ui/core/styles'
import Footer from './Footer'
import Navbar from './Navbar'
import useSiteMetadata from './SiteMetadata'
import MetaTags from './MetaTags'
import '../styles/main.scss'
import '../styles/custom-animations.scss'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    minHeight: '90vh',
  },
}))

const TemplateWrapper = ({ children, location, ...rest }) => {
  const { pathname } = location
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Helmet>
        <html lang="en" />
        {/* <meta name="description" content={description} /> */}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        {/* <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        /> */}
      </Helmet>
      <MetaTags
        pathname={pathname}
        pageThumbnailImage={{
          src: '/img/logo-v1.jpg',
        }}
      />
      <Navbar location={location} />
      <div className={classes.content}>{children}</div>
      <Footer />
    </div>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  rest: PropTypes.object,
}

TemplateWrapper.defaultProps = {
  children: null,
  location: null,
  rest: null,
}

export default TemplateWrapper
