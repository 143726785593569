import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { fade } from '@material-ui/core/styles/colorManipulator'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import navConfig from './navConfig'
import CustomPageContext from '../Context/CustomPageContext'
import CustomLink from './CustomLink'

const drawerWidth = 240;
const navBarFadeValue = 0.95

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: fade(theme.palette.primary.main, navBarFadeValue),
    [theme.breakpoints.up('sm')]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      width: '100%',
      // marginLeft: drawerWidth,
    },
  },
  navBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    height: '100%',
  },
  titleLogoContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 40,
    padding: '0 20px',
    backgroundColor: fade(theme.palette.primary.light, navBarFadeValue - 0.2),
    transform: 'skew(-15deg)',
  },
  navItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  navItem: {
    padding: 0,
    transform: 'skew(-15deg)',
    display: 'flex',
    alignItems: 'center',
  },
  navButton: {
    color: 'white',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 0,
  },
  themeToggleButtonContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  themeToggleButton: {},
  menuButtonContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    borderRadius: 0,
    color: theme.palette.text.primary,
    backgroundColor: fade(theme.palette.primary.main, navBarFadeValue),
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(2),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}))

const navItemIsSelected = (locationObj, href) => {
  try {
    if (locationObj && Object.keys(locationObj).length > 0 && href) {
      const { pathname } = locationObj
      if (pathname && pathname === href) return true
      return (href !== '/' && pathname.toString().startsWith(href))
    } throw new Error('locationObj/href invalid!')
  } catch (err) {
    console.error(`navItemIsSelected: ${err.toString()}`)
  }
  return false
}

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
}

const ResponsiveDrawer = ({ children, location, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const customPageContext = useContext(CustomPageContext)
  const [mobileOpen, setMobileOpen] = useState(false)
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  console.log('ResponsiveDrawer -> matches', matches)
  console.log('ResponsiveDrawer -> customPageContext.uiTheme', customPageContext.uiTheme)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const navItems = (
    <>
      <List>
        {navConfig.map((obj) => (
          <Link to={obj.href} style={{ color: 'unset', textDecoration: 'none' }} key={obj.href}>
            <ListItem>
              <div
                key={obj.href}
                style={{
                  padding: 0,
                  transform: 'skew(-15deg)',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 10,
                }}
              >
                <Button
                  variant="text"
                  className={classes.navButton}
                  style={{
                    backgroundColor: navItemIsSelected(location, obj.href) ? theme.palette.secondary.main : 'unset',
                  }}
                // startIcon={obj.icon}
                >
                  {obj.title}
                </Button>
              </div>
            </ListItem>
          </Link>
        ))}
      </List>
    </>
  )

  const drawer = (
    <Paper className={classes.drawerPaper}>
      <div className={classes.toolbar} />
      <Divider />
      {navItems}
    </Paper>
  )

  return (
    <div className={classes.root}>
      <HideOnScroll {...rest}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{ alignItems: 'stretch', height: '100%' }}>
            <Grid
              container
              justify="space-between"
              direction="row"
              alignItems="stretch"
            >
              <Grid
                item
                xs={9}
                sm={10}
                md={10}
                lg={10}
                xl={10}
              >
                <div className={classes.navBarContainer}>
                  <div className={classes.titleLogoContainer}>
                    <CustomLink to="/">
                      <Typography variant="h6" noWrap>
                        Creations By Nilay
                      </Typography>
                    </CustomLink>
                  </div>
                  <div className={classes.navItemContainer}>
                    {navConfig.map((obj) => (
                      <div key={obj.href} className={classes.navItem}>
                        <Link to={obj.href} style={{ color: 'unset', textDecoration: 'none' }}>
                          <Button
                            variant="text"
                            className={classes.navButton}
                            style={{
                              backgroundColor: navItemIsSelected(location, obj.href) ? theme.palette.secondary.main : 'unset',
                            }}
                          // startIcon={obj.icon}
                          >
                            {obj.title}
                          </Button>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={2}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                className={classes.themeToggleButtonContainer}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => customPageContext.setUiTheme((old) => (old === 'dark' ? 'light' : 'dark'))}
                  className={classes.themeToggleButton}
                >
                  {customPageContext.uiTheme === 'dark' ? <WbIncandescentIcon /> : <Brightness4Icon />}
                </IconButton>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                className={classes.menuButtonContainer}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <HideOnScroll>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            {mobileOpen && drawer}
          </Hidden>
        </nav>
      </HideOnScroll>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

ResponsiveDrawer.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  rest: PropTypes.object,
}

ResponsiveDrawer.defaultProps = {
  children: null,
  location: null,
  rest: null,
}

export default ResponsiveDrawer
