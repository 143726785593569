/**
 * App Config Object
 */
const appConfig = {
  /**
   * Default theme to init at first page load
   * - values: `dark`, `light`
   */
  defaultInitTheme: 'light',
}

export default appConfig
